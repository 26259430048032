<template>
	<div class="page">
	<router-view name="Header" class="header"/>
	<router-view class="view"/>
	<router-view name="Footer" class="footer"/>
  </div>
</template>

<style lang="scss" scoped>

	.page {
		height: 100vh;
		display: flex;
		flex-direction: column;
		overflow: hidden;
	}
	.header {
		flex: 0 0 auto;
	}
	.view {
		flex: 1 1 auto;
		overflow-x: hidden;
		overflow-y: auto;
	}
	.footer {
		flex: 0 0 auto;
	}
</style>