
const CallBackPage = () => import('@/components/auth/\@views/CallBackPage.vue')

export default [
    {
        path: '/',
        redirect: 'callback',
        children: [
            {
                path: '',
                name: 'callback',
                meta: {
                    title: 'Sign In'
                },
                components: {
                    default: CallBackPage,
                    Header: () => import('@/components/layout/PlainHeader.vue'),
                },
            },
            {
                path: 'verify-sent',
                name: 'verifySent',
                component: () => import('@/components/auth/\@views/VerifySent.vue')
            }
        ]
    }
]