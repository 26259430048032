const CONSTANTS = {
	sessionTimeout: 1000 * 60 * 180,
	sessIdTokenTimeout: 1000000,
	errorStatuses: [401],
	tagsArgs: ['skip', 'take', 'filter'],
	searchGridArgs: ['take', 'skip', 'group', 'sort', 'filter'], // TODO: add rem. opts
	timelineArgs: ['filter', 'search'],
	permissions:
	[
		{
			key: 'Cabinet Management',
			items: 
			[
				{itemKey: 'cabinet_details_permission', name: 'Update Cabinet Details', description: 'Allows the user to edit cabinet details.'},
				{itemKey: 'create_cabinet_metadata_permission', name: 'Create Metadata Fields', description: 'Allows the user to create new metadata columns.'},
				{itemKey: 'delete_cabinet_metadata_permission', name: 'Delete Metadata Fields', description: 'Allows the user to delete existing metadata columns.'},
			]
		},
		{
			key: 'Cabinet Operations',
			items: [
				{itemKey: 'write_document_metadata_permission', name: 'Modify Metadata', description: 'Allows the user to update document metadata.'},
				{itemKey: 'tags_permission', name: 'Manage Tags', description: 'Allows the user to create, apply, and remove tags.'},
				{itemKey: 'download_permission', name: 'Download Documents', description: 'Allows the user to download documents.'},
				{itemKey: 'upload_permission', name: 'Upload Documents', description: 'Allows the user to upload documents.'},
				{itemKey: 'split_doc_permission', name: 'Split Documents', description: 'Allows the user to split documents.'},
			]
		}
	],
	permissionKeys: {
		cabinetDetails: 'cabinet_details_permission',
		createCabinetMD: 'create_cabinet_metadata_permission',
		deleteCabinetMD: 'delete_cabinet_metadata_permission',
		writeMD: 'write_document_metadata_permission',
		tags: 'tags_permission',
		download: 'download_permission',
		upload: 'upload_permission',
		splitDoc: 'split_doc_permission',
	},
	hotkeys: {
        search: {
            space: 'Space',
            method: 'onHitSpace'
        }
    }
}

export default CONSTANTS