import { defineStore } from 'pinia'
import _ from 'lodash'
import api from '@/services/api'

import CONSTANTS from '@/services/constants'
import Cookies from 'universal-cookie'

import { useToastStore } from '@/stores/toast'

import { useQueryData } from '@/composables/queryDataComp'
import { useMutateData } from '@/composables/mutateDataComp'

import CHECK_EMAIL_QUERY from '@/graphql/auth/checkEmail.query.gql'

import REGISTER_AUTH0_USER_MUTATION from '@/graphql/auth/createAuth0User.mutation.gql'
import RESEND_VERIFICATION_EMAIL from '@/graphql/auth/resendVerificationEmail.mutation.gql'

const cookies = new Cookies()

export const useAuthStore = defineStore('auth-store', {
  state: () => ({
    userData: {},
    userConfig: {},
    readOnlyColumns: ['doc_title', 'selected', 'page_count', 'has_split'],
    passwordStrength: 0,
    loginMode: 'fail',
    errorLogin: null,
    errorRegister: null,
    isCredsAuthenticated: false,
    jwt: '',
    idToken: '',
    isAdmin: false,
  }),
  getters: {
    isAuthenticated: (state) => {
      return state.isCredsAuthenticated //|| !!cookies.get('access-token')
    },
    getJWT: () => {
      return localStorage.getItem('jwt')
    },
    isLoggedIn(state) {
      return !_.isEqual({}, state.userData)
    },
    userID(state) {
      return state.userData.user_id
    },
    validPasswordLength: () => {
      return (p) => p ? p.length >= 8 && p.length <= 32 : false
    },
    getStrengthText: (state) => {
      switch (state.passwordStrength) {
        case 0:
          return 'Very Weak'
        case 1:
          return 'Very Weak'
        case 2:
          return 'Weak'
        case 3:
          return 'Okay'
        case 4:
          return 'Strong'
        case 5:
          return 'Very Strong'
      }
    },
    getColorStrength: (state) => {
      switch (state.passwordStrength) {
        case 0:
          return 'darkred'
        case 1:
          return 'darkred'
        case 2:
          return 'red'
        case 3:
          return 'orange'
        case 4:
          return 'green'
        case 5:
          return 'blue'
      }
    }
  },
  actions: {
    setAuth(isAuthenticated) {
      this.isCredsAuthenticated = isAuthenticated
    },
    getIdToken() {
      const AUTH0_SPA_PATH = `@@auth0spajs@@::${process.env.VITE_AUTH0_CLIENT_ID}::`
      const idTokenStore = localStorage.getItem(`${AUTH0_SPA_PATH}@@user@@`)
      return this.idToken = idTokenStore && JSON.parse(idTokenStore).id_token
    },
    getEmailAuth0() {
      const AUTH0_SPA_PATH = `@@auth0spajs@@::${process.env.VITE_AUTH0_CLIENT_ID}::`
      const idTokenStore = localStorage.getItem(`${AUTH0_SPA_PATH}@@user@@`)
      const tokenStore = idTokenStore && JSON.parse(idTokenStore).decodedToken
      const email = tokenStore && tokenStore.user.email
      return email
    },
    setCookieJwt(accessToken) {
      const cookieConfig = {
        path: '/',
        // sameSite: 'None',
        expires: new Date(Date.now() + CONSTANTS.sessIdTokenTimeout)
      }
      cookies.set('access-token', accessToken, cookieConfig)
    },
    invalidAuthLogout() {
      const { callToast } = useToastStore()
      callToast({message: 'Invalid authentication token, logging out.', type: 'error', visibility: true})
    },
    async checkEmail(email) {
      const opts = {
        query: CHECK_EMAIL_QUERY,
        variables: {email},
      }

      const { data, error } = await useQueryData(opts)
      if(!error.value) {
        const { check_email } = data.value
        this.isAdmin = check_email.isAdmin
        this.userData['user_id'] = check_email.id || null
        return check_email
      } 
      else {
        console.log('Error in checking email: ', error.value)
        return false
      }
    },
    async fetchUserData(userId, params) {
      const { data } = await api.get(`users/${userId}`, {params})
      const { user } = data
      this.userData = user
    },
    async signupAuth0(credentials) {
      const opts = {
        mutation: REGISTER_AUTH0_USER_MUTATION,
				variables: { input: credentials }
      }
      return await useMutateData(opts)
    },
    async registerUserFromAuth0(user) {
      let isNewUser = false
      const email = user.value.email
      
      const res = await this.checkEmail(email)
      const isAvailable = res ? res.status === 'ok' : true
      const name = email ? email.split('@')[0] : ''
      
      if (isAvailable) {
        const params = {
          firstName: user.value.given_name || name,
          lastName:  user.value.family_name || name,
          email,
          username: user.value.nickname || name
        }
        const { error } = await this.signupAuth0(params)
        if (!error.value) {
          this.setAuth(true)
        } 
        else 
          console.log('Error registering user: ', error.value)

        isNewUser = true
      }
      
      return isNewUser
    },
    async resendVerificationEmail(email) {
      const opts = {
        mutation: RESEND_VERIFICATION_EMAIL,
				variables: { email }
      }
      const { data, error } = await useMutateData(opts)

			if(error.value) {
        console.log('Error sending verification email: ', error.value)
      } else {
        const { callToast } = useToastStore()
        callToast({ message: 'Verification has been sent to ' + email, type: 'success', visibility: true })
      }
    },
  }
})
