import { defineStore } from 'pinia'
import _ from 'lodash'

import { useRoute } from 'vue-router'
import { useUserStore } from '@/components/user/\@stores/user'
import { useToastStore } from '@/stores/toast'

import api from '@/services/api'

import CustomStore from 'devextreme/data/custom_store'

import CABINET_SCHEMAS_QUERY from '@/graphql/cabinet/cabinetSchema.query.gql'
import CABINETS_QUERY from '@/graphql/cabinet/cabinets.query.gql'
import CABINET_QUERY from '@/graphql/cabinet/cabinet.query.gql'
import CABINET_MUTATION from '@/graphql/cabinet/cabinet.mutation.gql'

import DELETE_SCHEMA_MUTATION from '@/graphql/cabinet/deleteSchemaField.mutation.gql'
import UPLOAD_SCHEMA_MUTATION from '@/graphql/cabinet/uploadSchemaField.mutation.gql'

import { useQueryData } from '@/composables/queryDataComp'
import { useMutateData } from '@/composables/mutateDataComp'

export const useCabinetStore = defineStore('cabinetStore', {
	state: () => ({
		cabinetsData: [],
		metadata: [],
		modalVisible: false,
		cabColumns: null,
		readOnlyColumns: ['doc_title', 'selected', 'page_count', 'has_split'],
		columnSchema: [],
		cabinetId: null,
		title: '',
		description: '',
		popupVisible: false,
		popupMemberVisible: false,
		reloadCabinets: false,
		popLoader: false,
		curMember: null,
		curMemberRole: null,
		reloadMemberList: false,
	}),
	getters: {
		getColumnSchema: (state) => state.columnSchema,
		getCabinetId: (state) => state.cabinetId,
		getVisibleColumns: (state) => state.cabColumns,
		getPopupVisibility: (state) => state.popupVisible
	},
	actions: {
		setCabinetId() {
			const route = useRoute()
			this.cabinetId = route.params?.cabinetID
		},
		setPopupVisible(stat) {
			this.popupVisible = stat
		},
		visibleColumns() {
            return this.cabinetColumns({ pickedFields: ['dataField', 'dataType']})
        },
		cabinetColumns (options) {
            // returns a method so we can have arguments

            options = options || {}
            // current options list
            // showHidden (default false): when true shows all columns whether they're visible or not
            // showReadOnly (default false): when true show all columns whether they're editable or not
			const userStore = useUserStore()
            let columns = _.filter(userStore.userConfig, column => {
                if (!options.showHidden && !column.visible)
                    return false
                else if (!options.showReadOnly && this.readOnlyColumns.includes(column.dataField))
                    return false
                return true
            })

            columns = _.sortBy(columns, ['visibleIndex'])
            if (options.pickedFields)
                columns = _.map(columns, column => {
                    return _.pick(column, options.pickedFields)
                })

			return this.cabColumns = columns
        },
		async queryCabinets() {
			const params = {
				query: CABINETS_QUERY,
                opts: {fetchPolicy: "no-cache"},
			}
			return await useQueryData(params)
        },
        async fetchDataSource() {
			return new CustomStore({
				key: 'cabinet_id',
				load: async (loadOptions) => {
                    return await this.fetchCabinets()
                }
			})
		},
		async fetchCabinet() {
			const params = {
				query: CABINET_QUERY,
				variables: {cabinetId: this.cabinetId},
				opts: {fetchPolicy: "no-cache"},
			}

			const { data, error } = await useQueryData(params)
			if (error.value) {
				console.log('Error fetching cabinet: ', error.value)
			} else {
				const { cabinet } = data.value
				const { name, description } = cabinet

				this.title = name
				this.description = description
			}
		},
		async fetchCabinets() {
			const { data, error } = await this.queryCabinets()
			if (error.value) {
				console.log('Error fetching cabinets: ', error.value)
				this.cabinetsData = []
				return null
			} else {
				const { cabinets } = data.value
				return this.cabinetsData = cabinets
			}

		},
		async fetchDocsetSchema(cabinetId) {
			const params = {
				query: CABINET_SCHEMAS_QUERY,
				variables: {cabinetId},
				opts: {fetchPolicy: "no-cache"},
			}
			const { data, error } = await useQueryData(params)

			if(error.value) {
				return Promise.reject(error.value)
			}
			else {
				const { cabinet_schema } = data.value
				this.columnSchema = cabinet_schema.schema
				return Promise.resolve(cabinet_schema)
			}
		},
		async addCabinet(params) {
			const opts = {
				mutation: CABINET_MUTATION,
				variables: {input: params}
			}

			return await useMutateData(opts)
		},
		async updateCabinet(cabinetId, params) {
			return await api.patch(`/cabinets/${cabinetId}`, params)
		},		
		async removeCabinetField(params) {
			const opts = {
                mutation: DELETE_SCHEMA_MUTATION,
				variables: params
            }

            const {data , error} = await useMutateData(opts)
			if(!error.value) {
				return data.value
			} else {
				const {callToast} = useToastStore()
				console.log('Error deleting schema field(s): ', error.value)
				// callToast({ message: error.message, type: 'error', visibility: true })
				return error.value
			}
		},

		async uploadCabinetField(params) {
			const opts = {
                mutation: UPLOAD_SCHEMA_MUTATION,
				variables: params
            }

            return await useMutateData(opts)
		},
		openCabinetFieldPopup() {
			this.modalVisible = true
		}
	}
})