import axios from 'axios'
import { useAuthStore } from '@/components/auth/\@stores/auth'
import CONSTANTS from '@/services/constants'
// import { useRouter } from 'vue-router'
import router from '@/router'

import { useToastStore } from '@/stores/toast'

const instance = axios.create({
    baseURL: process.env.API_URL,
    // headers: {
    //     'Content-type': 'application/json'
    // },
    timeout: 30000,
})

const onPreRequest = (config) => {

    if(config) {
        const authStore = useAuthStore()
        const token = authStore.getIdToken()
        if (token) {
            const { headers } = config
            headers['Authorization'] = `Bearer ${token}`
            const newHeaders = { ...headers}
            
            return {...config, newHeaders}
        }
    }

    return config
}

const onResponse = (response) => response

const onPreRequestError = (error) => {
    return Promise.reject(error)
}

const onResponseError = (error) => {
    // const router = useRouter()
    const { response } = error
    const { data } = response
    if (response.status && CONSTANTS.errorStatuses.includes(response.status))
        router.push({name: 'cabinets'})

    return Promise.reject(response)
}

instance.interceptors.request.use(onPreRequest, onPreRequestError);
instance.interceptors.response.use(onResponse, onResponseError);

export default instance

