<template>

</template>

<script setup>
import { onMounted } from 'vue'

import { useAuthStore } from '@/components/auth/\@stores/auth'
const authStore = useAuthStore()
const src = `https://widget.usersnap.com/global/load/${process.env.USERSNAP_ID}?onload=onUsersnapLoad`

onMounted(() => {
  window.onUsersnapLoad = function(api) {
    api.init();

    api.on('open', function(event) {
      if (authStore.userData && authStore.userData.email) {
        event.api.setValue('custom', {
          user_id: authStore.userData.id,
          user_email: authStore.userData.email,
          user_name: authStore.userData.name,
          roles: authStore.userData.roles
        })
      }
    });
  };
  var script = document.createElement('script');
  script.defer = 1;
  script.src = src
  document.getElementsByTagName('head')[0].appendChild(script);
})

</script>

<style scoped lang="scss">

</style>