import { defineStore } from 'pinia'
import _ from 'lodash'
import api from '@/services/api'

import { useQueryData } from '@/composables/queryDataComp'
import { useMutateData } from '@/composables/mutateDataComp'

import USER_CONFIG_QUERY from '@/graphql/user/userConfig.query.gql'
import USER_CONFIG_UPDATE_MUTATION from '@/graphql/user/userConfigUpdate.mutation.gql'

import USER_DOWNLOADS_QUERY from '@/graphql/user/userDownloads.query.gql'

import CustomStore from 'devextreme/data/custom_store'

export const useUserStore = defineStore('user-store', {
    state: () => ({
        downloads: null,
        userConfig: null,
        curUid: null,
    }),
    getters:
    {
        getUserColumnConfig: (state) => state.userConfig,
    },
    actions: {
		async fetchDownloads() {
            const opts = {
				query: USER_DOWNLOADS_QUERY,
				opts: {fetchPolicy: "no-cache"},
			}
			const { data, error } = await useQueryData(opts)

            if (!error.value) {
                const { user_downloads } = data.value
                this.downloads = user_downloads
            } else {
                console.log('Error fetching user download data: ', error.value)
            }
        },
        async loadUserConfig(params) {
            const opts = {
				query: USER_CONFIG_QUERY,
                variables: params,
				opts: {fetchPolicy: "no-cache"},
			}
			const { data, error } = await useQueryData(opts)

            if(error.value) {
                return []
            } else {
                const { user_configs } = data.value
                return this.userConfig = user_configs.config
            }
        },
        async setUserConfig(config) {
            const { schema } = config
            if (!schema || schema.length <= 0 || _.isEqual(this.userConfig, schema))
                return

            const configObj = schema.map( column => _.pick(column, ['name', 'width', 'visible', 'dataType', 'dataField', 'visibleIndex', 'fixed']))
            const opts = {
				mutation: USER_CONFIG_UPDATE_MUTATION,
                variables: {input: {...config, schema: configObj}}
			}

			const { error } = await useMutateData(opts)

            if (error.value) {
                console.log('Error creating user config: ', error.value)
            } else {
                this.userConfig = configObj
            }

            return configObj
        },
        async fetchUsers(params) {
            return await api.get('/users', {params})
        },
        fetchDataSource(params) {
            return new CustomStore({
                loadMode: 'raw',
                key: 'id',
                load: async (loadOptions) => {
                    const { data } = await this.fetchUsers(params)
                    const { users } = data
                    return users
                },
            })
        }
    }
})