import { authGuard } from '@auth0/auth0-vue'

import { useCabinetStore } from '@/components/cabinets/\@stores/cabinets'
import { useSearchStore } from '@/components/search/\@stores/search'

export default [
    {
        path: '/cabinets/:cabinetID',
        name: 'search',
        meta: {
            title: 'Search Page',
            beforeEach: (to, from, next) => {
                const cabinetStore = useCabinetStore()
                const searchStore = useSearchStore()

                cabinetStore.cabinetId = to.params.cabinetID
                searchStore.resetMasterParams()

                next()
            }
        },
        beforeEnter: authGuard,
        components: {
            default: () => import('@/components/search/\@views/Search.vue'),
            Header: () => import('@/components/layout/Header.vue'),
        },
    },
]