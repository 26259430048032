import { authGuard } from '@auth0/auth0-vue'

import { useCabinetStore } from '@/components/cabinets/\@stores/cabinets'
import { useSearchStore } from '@/components/search/\@stores/search'

export default [{
    path: '/cabinets/:cabinetID/settings/',
    name: 'cabinetSettings',
    redirect: { name: 'cabinetGeneralSettings' },
    components: {
      default: () => import('@/components/cabinets/settings/\@views/CabinetSettings.vue'),
      Header: () => import('@/components/layout/Header.vue'),
      
    },
    meta: {
      beforeEach: (to, from, next) => {
        const cabinetStore = useCabinetStore()
        cabinetStore.cabinetId = to.params.cabinetID

        next()
      }
    },
    beforeEnter: authGuard,
    children: [
      {
        path: 'general',
        name: 'cabinetGeneralSettings',
        component: () => import('@/components/cabinets/settings/GeneralSettingsScreen.vue')
      },
      {
        path: 'members',
        name: 'cabinetManageMembers',
        component: () => import('@/components/cabinets/settings/MembersScreen.vue')
      },
      {
        path: 'metadata',
        name: 'cabinetManageMetadata',
        component: () => import('@/components/cabinets/settings/MetadataManagement/ManageMetadataScreen.vue')
      },
      {
        path: 'uploadsMetadata/',
        name: 'uploadsMetadata',
        redirect: { name: 'metadataChooseUpload' },
        children: [
          {
            path: 'pastUploads',
            name: 'metadataChooseUpload',
            component: () => import('@/components/cabinets/settings/MetadataManagement/UploadMetadataScreen.vue')
          },
          {
            path: 'applyMetadata',
            name: 'metadataUploadApply',
            component: () => import('@/components/cabinets/settings/MetadataManagement/ApplyUploadMetadata.vue')
          }
        ]
      },
      {
        path: 'upload',
        name: 'cabinetUpload',
        component: () => import('@/components/cabinets/settings/DocManagement/UploadScreen.vue')
      },
      {
        path: 'download',
        name: 'cabinetDownload',
        component: () => import('@/components/cabinets/settings/DocManagement/DownloadScreen.vue')
      },
      {
        path: 'retention',
        name: 'cabinetRetention',
        component: () => import('@/components/cabinets/settings/DocManagement/RetentionScreen.vue')
      },
      {
        path: 'redaction',
        name: 'cabinetRedaction',
        component: () => import('@/components/cabinets/settings/DocManagement/RedactionScreen.vue')
      },
    ]
  }
]