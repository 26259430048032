import { authGuard } from '@auth0/auth0-vue'

const EmptyPage = () => import('@/components/EmptyRouterView.vue')

export default [
    {
        path: '/user/',
        name: 'userPage',
        beforeEnter: authGuard,
        components: {
            default: EmptyPage,
            Header: () => import('@/components/layout/Header.vue'),
        },
        children: [
            {
                path: 'downloads',
                name: 'userDownloads',
                component: () => import('@/components/user/\@views/UserDownloads.vue')
            },
        ]
    }
]