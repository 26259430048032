import { createRouter, createWebHistory } from 'vue-router'

import Cookies from 'universal-cookie'

import { useAuthStore } from '@/components/auth/\@stores/auth'
import { useToastStore } from '@/stores/toast'

import adminRoutes from '@/components/admin/\@router'
import loginRoutes from '@/components/auth/\@router'
import userRoutes from '@/components/user/\@router'
import searchRoutes from '@/components/search/\@router'
import cabinetsRoutes from '@/components/cabinets/\@router'
import cabinetSettingsRoutes from '@/components/cabinets/settings/\@router'
import basketRoutes from '@/components/baskets/\@router'
import documentRoutes from '@/components/document/\@router'
import docSplitterRoutes from './docSplitterRouter'

const cookies = new Cookies()

const baseRoutes = [
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@/components/auth/Logout.vue')
    },
    {
        path: '/*',
        name: '404',
        component: () => import('@/components/404Screen.vue')
    }
]

const routes = [
    ...baseRoutes,
    ...adminRoutes,
    ...loginRoutes,
    ...userRoutes,
    ...searchRoutes,
    ...cabinetsRoutes,
    ...cabinetSettingsRoutes,
    ...basketRoutes,
    ...documentRoutes,
    ...docSplitterRoutes
]

const router = createRouter({
    history: createWebHistory(import.meta.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {

    const route = to.matched
    const auth = cookies.get(`auth0.${process.env.VITE_AUTH0_CLIENT_ID}.is.authenticated`)
    const isAuthenticated = auth && JSON.parse(auth)

    // TODO: implement session timeout; save and expire token in cookies
    if (to.name === 'logout')
        next()
    else if (isAuthenticated) {
        const { checkEmail, getEmailAuth0, fetchUserData } = useAuthStore()
        const email = getEmailAuth0()
        const res = await checkEmail(email) //const { status, isApproved, isAdmin } = await checkEmail(email)
        const isNewUser = res && res.status === 'ok'

        if (!isNewUser && res.id) {
            // store user details
            fetchUserData(res.id, { flattened: true })
        }

        if (res && (!isNewUser && !res.isApproved && to.name !== 'document-viewer')) { // *exempt document viewer direct access
            const { callToast } = useToastStore()
            callToast({ message: 'You are not yet approved by the admin.', type: 'warning', visibility: true })
            next({ path: '/logout' })
        }
        else if (route[0].meta.beforeEach)
            route[0].meta.beforeEach(to, from, next)
        else if (to.name === 'callback' || (to.path.match(/admin.*/gi) && !res.isAdmin)) {
            /*if (to.query.masterparams) {
                const searchStore = useSearchStore()
                searchStore.setMasterParams(to.query.masterparams)
            }*/
            next({ name: 'cabinets' })
        }
        else {
            next()
        }
    }
    else {
        next()
    }

})

export { routes }

export default router