<template>
</template>

<script setup>
import notify from 'devextreme/ui/notify'

import { watchEffect, computed } from 'vue'
import { useToastStore } from '@/stores/toast'

const toastStore = useToastStore()

const type = computed(() => toastStore.type)
const message = computed(() => toastStore.message)

const show = () => {
  const position = 'bottom center'
  const direction = 'up-stack'

  notify({
    message: message.value,
    height: 45,
    width: 400,
    minWidth: 250,
    type: type.value,
    displayTime: 3500,
    animation: {
      show: {
        type: 'fade', duration: 400, from: 0, to: 1,
      },
      hide: { type: 'fade', duration: 40, to: 0 },
    },
  },
  { position, direction })
}

watchEffect(() => {
  if(toastStore.visible) {
    show()
    toastStore.visible = false
  }
})
</script>

<style lang="scss">
.dx-toast-message {
  padding: .5rem;
}
</style>