import { defineStore } from 'pinia'

export const useToastStore = defineStore('toast-store', {
  state: () => ({
    visible: false,
    message: '',
    type: 'info',
  }),
  getters: {
    getToastData(state)  {
      return state.items
    },
  },
  actions: {
    callToast({visibility, message, type}) {
      this.visible = visibility
      this.message = message
      this.type = type
    }
  }
})
