import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, concat } from '@apollo/client/core'
import createUploadLink from 'apollo-upload-client/public/createUploadLink.js'

import fetch from 'cross-fetch'
import Cookies from 'universal-cookie'

const cookies = new Cookies()
const AUTH0_SPA_PATH = `@@auth0spajs@@::${process.env.VITE_AUTH0_CLIENT_ID}::`
const AUTH0_ACCESS_TOKEN_PATH = 'default::openid profile email offline_access'

const customFetch = (uri, options) => {
  const idTokenStore = localStorage.getItem(`${AUTH0_SPA_PATH}@@user@@`)
  const idToken = idTokenStore && JSON.parse(idTokenStore).id_token
  const body = options.body ? JSON.parse(options.body) : {}
  options = {...options, body: JSON.stringify({...body, idToken})}

  return fetch(`${uri}`, options)
}

const terminatingLink = createUploadLink({ 
  uri: process.env.VUE_APP_API_TARGET,
  credentials: 'same-origin',
  fetch: customFetch,
})

// TODO: auth interceptor
const authMiddleware = new ApolloLink( async(operation, forward) => {

  // add the authorization to the headers
  const localStore = localStorage.getItem(AUTH0_SPA_PATH + AUTH0_ACCESS_TOKEN_PATH)
  const tokenObj = localStore ? JSON.parse(localStore).body : {}
  const accessToken = tokenObj.access_token || ''
  // const { getAccessTokenSilently } = useAuth0()
  // const accessToken = await getAccessTokenSilently()

  operation.setContext({

    headers: {

      authorization: accessToken ? `Bearer ${accessToken}` : "",

    },

  });

  return forward(operation);

});

// export const apolloClient = new ApolloClient({
  
//   link: concat(authMiddleware, httpLink),

//   cache: new InMemoryCache(),

// });

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: concat(authMiddleware, terminatingLink),//ApolloLink.from([authMiddleware, terminatingLink]),
})
