import { authGuard } from '@auth0/auth0-vue'

export default [
    {
        path: '/doc_splitter/:cabinetID/:basketID/:basketIndex',
        name: 'document-splitter',
        meta: {
            title: 'Document Splitting Page',
        },
        components: {
            default: () => import('@/components/doc-splitter/DocSplitterLayout.vue'),
            Header: () => import('@/components/layout/Header.vue'),

        },
        beforeEnter: authGuard,
    }
]