import { ref } from 'vue'
import { provideApolloClient, useApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/utilities/apollo-client'

export async function useMutateData({mutation, variables, opts}) {
    provideApolloClient(apolloClient)
    const { resolveClient } = useApolloClient()

    const data = ref(null)
    const error = ref(null)
    const client = resolveClient()

    await client.mutate({
        mutation,
        variables,
        ...opts
    })
    .then(r => r.data)
    .then(res => data.value = res)
    .catch(e => error.value = e)

    return {
        data,
        error
    }
}