import { defineStore } from 'pinia'
import { toRaw } from 'vue'
import { useCabinetStore } from '@/components/cabinets/\@stores/cabinets'

export const useSearchStore = defineStore('search-store', {
  state: () => ({
    totalCount: 0,
    headerFilter: [],
    selected: [],
    selectedCount: 0,
    selectedLoading: false,
    searchObjects: null,
    singleCellEditing: false,
    masterParams: {},
    isFilteredByTags: false,
    isFilteredByTimeline: false,
    clearAll: false,
  }),

  getters: {
    getHeaderFilter: (state) => state.headerFilter,
    getSearchData: (state) => state.searchObjects,
    //removed since its not being called on the original vue2 assets
    // getSearchType(state)  {
    //   return state.searchTypes
    // },
    getSearchObjects: (state) => state.searchObjects,
    getMasterParams: (state) => {
			const cabinetStore = useCabinetStore()
			let params = {}

			if (state.searchObjects && state.searchObjects.length)
				params.search = toRaw(state.searchObjects)

      if (state.headerFilter && state.headerFilter.length)
				params.filter = state.headerFilter

			const visibleColumns = cabinetStore.visibleColumns()
      if (visibleColumns.length)
				params.visibleColumns = visibleColumns

			return state.masterParams = params

		},
    getDocParams: (state) => {
			return {
				skip: state.skip,
				take: state.take,
				...state.masterParams
			}
		},
    textSearchObjects: (state) => state.searchObjects && JSON.stringify(state.searchObjects.filter( obj => { return obj.type === 'text' }))
  },
  actions: {
    setSearches(searchObjects) {
        let searchSet = new Set()
        const searchObjs = typeof searchObjects === 'string' ? JSON.parse(searchObjects) : searchObjects

        if (searchObjs && searchObjs.length) {
          return searchObjs.filter(obj => {
            if (!obj)
              return
            // only text needs to be checked for unique here because setSearches is only called from the
            // DxTagbox component (SearchBar.vue)
            // also hardcoding text is kinda gross.
            if (obj.type !== 'text')
                return true

            const existsInSet = searchSet.has(obj.text)
            searchSet.add(obj.text)
            return !existsInSet
          })
        }
    },
    filterSearchesByTypes(obj, type) {
      const typesObj = obj.filter(r => r.type === type)
      const searchTypesObj = obj.filter(r => r.type !== type)
      return typesObj ? [ ...searchTypesObj, ...[typesObj[typesObj.length - 1]] ] : [...searchTypesObj]
    },
    filterColumnValue(filter) {
        const column = filter.column
        const value = filter.value
        const newFilter = [column, '=', value]
        if (this.headerFilter.length <= 0) {
            this.headerFilter = newFilter
        }
        else if (this.headerFilter[0].constructor.name === 'String') {
            if (this.headerFilter[0] === column)
                this.headerFilter = newFilter
            else
                this.headerFilter = [this.headerFilter, 'and', newFilter]
        }
        else {
            const existingIndex = this.headerFilter.findIndex(val => val[0] === column )
            if (existingIndex)
                this.headerFilter[existingIndex] = newFilter
            else
                this.headerFilter = [this.headerFilter, 'and', newFilter]
        }
    },
    excludeColumnValue(filter) {
        const column = filter.column
        const value = filter.value
        const newFilter = [column, '<>', value]
        if (this.headerFilter.length <= 0) {
          this.headerFilter = newFilter
        }
        else if (this.headerFilter[0].constructor.name === 'String') {
            if (this.headerFilter[0] === column && (this.headerFilter[1] === '<>' || state.headerFilter[1] === 'noneof')) {
                if (this.headerFilter[1] === 'noneof')
                  this.headerFilter[2].push(value)
                else
                  this.headerFilter.splice(1, 2, 'noneof', [this.headerFilter[2], value])
            }
            else
              this.headerFilter = [this.headerFilter, 'and', newFilter]
        }
        else {
            const existingIndex = this.headerFilter.findIndex(val => val[0] === column )
            if (existingIndex)
                if (this.headerFilter[existingIndex][1] === 'noneof')
                  this.headerFilter[existingIndex][2].push(value)
                else
                  this.headerFilter[existingIndex].splice(1, 2, 'noneof', [this.headerFilter[existingIndex][2], value])
            else
              this.headerFilter.push('and', newFilter)
        }
    },
    addSearchbarObj(newObj) {
      if(!this.searchObjects) {
        this.searchObjects = [newObj]
        return
      }

      const existingIndex = this.searchObjects.findIndex(val => val.type === newObj.type )
      // In the future we may want the ability to have multiple of one type.
      if (existingIndex === -1) { // non-existent
        this.searchObjects = [...this.searchObjects, newObj]
      } // existing: 0(1) approach
      else if (this.searchObjects[existingIndex]['text'] !== newObj['text']) {
        // const rawObj = toRaw(this.searchObjects)
        // Object.assign([], this.searchObjects, {[existingIndex]: newObj})
      
        this.searchObjects[existingIndex] = newObj

        // this.searchObjects = toRaw(this.searchObjects).splice(existingIndex, 1, newObj)//TODO: investigate - .splice()
      }
    },   
    removeSearchbarObj(type) {
      if(this.searchObjects) {
        //assumes there is only one of the target type
        const existingIndex = this.searchObjects.findIndex(val => val.type === type )
        if (existingIndex >= 0)
          this.searchObjects = toRaw(this.searchObjects).splice(existingIndex, 1)
      }
    },
    setHeaderFilter(filter) {
      this.headerFilter = filter
      Object.assign(this.masterParams, {filter})
    },
    setTotalCount(count) {
      this.totalCount = count
    },
    setSingleCellEditing(mode) {
      this.singleCellEditing = mode
    },
    setSelected(selected) {
      this.selected = selected
    },
    setSelectedLoading(loadingStatus) {
      this.selectedLoading = loadingStatus
    },
    setSelectedCount(count) {
      this.selectedCount = count
    },
    resetMasterParams() {
      this.searchObjects = null
      this.headerFilter = []
      this.masterParams = {}
    }
  }
})