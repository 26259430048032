
import { authGuard } from '@auth0/auth0-vue'

export default [
    {
        path: '/document/:docID',
        name: 'document-viewer',
        meta: {
            title: 'Cabinets Page',
        },
        components: {
            default: () => import('@/components/baskets/\@views/DocViewer.vue'),
            Header: () => import('@/components/layout/Header.vue'),

        },
        beforeEnter: authGuard,
    }
]