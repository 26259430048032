// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log("Vite ⚡️ Rails");

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log(
  "Visit the guide for more information: ",
  "https://vite-ruby.netlify.app/guide/rails"
);

// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'

// import Vue from "vue";
// import App from "../App.vue";
// import router from "../router";
// import store from "../vuex/vuexStore";
// import axios from "axios";
// import eventBus from "../eventBus";

// require("../theme/dx.generic.lease-analytics.css");
// require('../theme/dx.material.lease-analytics.css')
// import '../theme/dx.material.lease-analytics.css';
import { createAuth0 } from '@auth0/auth0-vue'

import { createApp, h, provide } from 'vue'
import { createPinia } from 'pinia'

import { DefaultApolloClient } from '@vue/apollo-composable'
import { apolloClient } from '@/utilities/apollo-client'

import App from '@/App.vue'
import router from '@/router'

const pinia = createPinia()

const app = createApp({
  setup() {
      provide(DefaultApolloClient, apolloClient)
  },
  render: () => h(App),
})

// const app = createApp(App)

app.use(pinia)
.use(router)
.use(
  createAuth0({
    domain: import.meta.env.VITE_AUTH0_DOMAIN,
    clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
    useRefreshTokens: true,
    // useRefreshTokensFallback: true,
    cacheLocation: 'localstorage',
    authorizationParams: {
      redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
    },
  })
)
.mount('#app')


// setting default axios header to include the JWT
/*axios.defaults.headers.common["Authorization"] = localStorage.jwt;

document.addEventListener("DOMContentLoaded", () => {
  new Vue({
    render: (h) => h(App),
    router,
    store,
    created() {
      // Axios will intercept all requests and check if there was an error with authorization.
      axios.interceptors.response.use(
        (response) => response,
        (error) => {
          if (error.response && error.response.status === 401) {
            console.log(
              "Axios interceptor detected bad authentication via 401 response code, logging out."
            );
            eventBus.$emit(
              "toast",
              "Invalid authentication token, logging out.",
              "error"
            );
            this.$store.dispatch("logout");
            return Promise.reject(error);
          }
          return Promise.reject(error);
        }
      );
    },
  }).$mount("#app");
});*/
