import { authGuard } from '@auth0/auth0-vue'

export default [
	{
		path: '/admin/',
		name: 'admin',
		redirect: { name: 'site-config' },
		beforeEnter: authGuard,
        components: {
            default: () => import('@/components/admin/\@views/Admin.vue'),
            Header: () => import('@/components/layout/Header.vue'),
        },
        children: [
			{
				path: 'site-config',
				name: 'site-config',
				component: () => import('@/components/admin/\@views/SiteConfiguration.vue')
			},
			{
				path: 'user-control',
				name: 'user-control',
				component: () => import('@/components/admin/\@views/UsersManagement.vue')
			},
			{
				path: 'access-control',
				name: 'access-control',
				component: () => import('@/components/admin/\@views/RolesManagement.vue')
			}
        ]
	}
]