import { authGuard } from '@auth0/auth0-vue'

export default [
    {
        path: '/cabinets/:cabinetID/:basketID/:basketIndex',
        name: 'docView',
        beforeEnter: authGuard,
        components: {
            default: () => import('@/components/baskets/\@views/DocViewer.vue'),
            Header: () => import('@/components/layout/Header.vue'),
        },
    },
]