
import { authGuard } from '@auth0/auth0-vue'

export default [
    {
        path: '/cabinets',
        name: 'cabinets',
        meta: {
            title: 'Cabinets Page',
        },
        components: {
            default: () => import('@/components/cabinets/\@views/Cabinet.vue'),
            Header: () => import('@/components/layout/Header.vue'),

        },
        beforeEnter: authGuard,
    },
    {
        path: '/new-cabinet',
        name: 'new-cabinet',
        meta: {
            title: 'New Cabinet Page',
        },
        components: {
            default: () => import('@/components/cabinets/NewCabinet.vue'),
            Header: () => import('@/components/layout/Header.vue'),

        },
        beforeEnter: authGuard,
    },
]